.carousel {
    position: relative;
    z-index: 0;
}

.slideWrap {
    min-height: calc(100vh - 40px);
    width: 100%;
    display: flex;
    padding: 80px;
    align-items: center;
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: center;
    background-color: rgb(0, 0, 0);
    justify-content: center;
    position: relative;
    filter: sepia(100%);
    transition: filter 3.5s;

    > * {
        opacity: 0;
        transform: translateY(55px);
        transition: all 0.4s;
    }

    @media (max-width: 760px) and (orientation: landscape) {
        min-height: 110vh;
        padding: 70px 20px;
    }

    @media (max-width: 760px) and (orientation: portrait) {
        padding: 70px 20px;
    }
}

.textWrap {
    max-width: 900px;
    text-align: center;
    background: rgba(104, 104, 104, 0.4);

    h2 {
        font-size: 4.5em;
        line-height: 1.1;
        color: $white;
        margin-bottom: 10px;
        text-shadow: 0 3px 5px rgba(0, 0, 0, 0.5);

        @media (max-width: $breakPoint) {
            font-size: 2.5em;
        }
    }

    p{
        font-size: 1em;
        color: #fff;
    }

    .btn {
        font-size: 1em;
        color: #fff;
        background-color: $primaryColor;
        display: inline-block;
        padding: 10px 30px;
        margin-top: 20px;
        text-decoration: none;
        text-transform: uppercase;
        letter-spacing: 2px;
        transition: 0.5s;

        &:hover {
            letter-spacing: 4px;
        }
    }

    > * {
        opacity: 0;
        transform: translateY(-45px);
        transition: all 1s;
    }
}

.swiper-slide-active {
    .slideWrap {
        filter: sepia(0%);

        > * {
            opacity: 1;
            transform: none;

            @for $i from 0 to 15 {
                &:nth-child(#{$i + 1}) {
                    transition-delay: $i * 0.1 + 0.3s;
                }
            }

            .btn { transition-delay: initial!important; }
        }
    }

    .textWrap {
        > * {
            opacity: 1;
            transform: none;

            @for $i from 0 to 15 {
                &:nth-child(#{$i + 1}) {
                    transition-delay: $i * 0.3 + 1  s;
                }
            }
        }
    }
}

.swiper-container {
    .swiper-button-prev,
    .swiper-button-next {
        color: $white;
        top: auto;
        bottom: 20px;
        width: 40px;
        height: 40px;
        border-radius: 50px;
        background: $primaryColor;

        &:after {
            font-size: 22px;
            font-weight: bold;
        }
    }

    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next {
        left: 50%;
        margin-left: -44px;
        right: auto;
    }

    .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev {
        left: 50%;
        margin-left: 4px;
        right: auto;
    }
}