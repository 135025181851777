.activities-list{
    overflow-wrap: break-word;
    .row {
        position: relative;
        width: 100%;
        display: flex;
        max-width: 1250px;
        margin: auto;
        justify-content: space-between;

        @media (max-width: $breakPoint) {
            flex-direction: column;
        }

        .column {
            position: relative;
            width: 100%;

            @media (max-width: $breakPoint) {
                width: 100%
            }
            .imgWrap {
                position: relative;
                width: 100%;
                height: 100%;
                min-width: 350px;

                @media (max-width: $breakPoint) {
                    margin-top: 25px;
                }

                img {
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }
}